import { useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Link,
} from "@mui/material";
import { useIntl } from "react-intl";

function _useStrings() {
  const intl = useIntl();

  const STRINGS = {
    HELP_TITLE: intl.formatMessage({
      id: "help-title",
      defaultMessage: "Pomoc IPZIN",
    }),
    // HELP_MSG: intl.formatMessage({
    //   id: "help-msg",
    //   defaultMessage: "",
    // }),
  };
  return STRINGS;
}

export default function HelpButton() {
  const [open, setOpen] = useState(false);
  const _STRINGS = _useStrings();
  return (
    <>
      <IconButton
        size="large"
        style={{ color: "white" }}
        onClick={() => setOpen(true)}
      >
        <HelpOutlineIcon />
      </IconButton>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>{_STRINGS.HELP_TITLE}</DialogTitle>
        <DialogContent>
          <Typography>
            W razie pytań i problemów technicznych prosimy o kontakt z działem
            IT: <Link href="mailto:it@ipzin.org">it@ipzin.org</Link>
            <br />W razie pytań merytorycznych dotyczących programu, prosimy o
            kontakt: <Link href="mailto:nwkp@ipzin.org">nwkp@ipzin.org</Link>
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
