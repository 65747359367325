import {
  useState,
  useEffect,
  createContext,
  PropsWithChildren,
  useContext,
} from "react";
import Script from "next/script";
import { GoogleDriveFile } from "../../Shared/Types/ElementGeneratedFile";
import { ClientSideOnly } from "../ClientSideOnly";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useAPICall, useCredentials } from "../../utils/queries";
import { useAuthUser } from "next-firebase-auth";
import useSessionState from "~/utils/Storage/SessionStorageUtils";

interface PickerProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  token: string;
  type: "folder" | "doc";
  onSelect: (file: GoogleDriveFile) => void;
}

interface GoogleDriveConfig {
  onSelect: (file: GoogleDriveFile) => void;
  type: "folder" | "doc";
}

interface GoogleDriveContextProps {
  isLoading: boolean;
  isAvailable: boolean;
  config: GoogleDriveConfig;
  setConfig: (c: GoogleDriveConfig) => void;
  open: boolean;
  setOpen: (o: boolean) => void;
}

/////////// Google Drive Context /////////////////
const GoogleDriveContext = createContext<GoogleDriveContextProps>({
  isLoading: true,
  isAvailable: false,
  open: false,
  setOpen: (b) => {},
  config: { onSelect: (f) => {}, type: "doc" },
  setConfig: (c) => {},
});
export function GoogleDriveContextProvider({
  children,
}: PropsWithChildren<{}>) {
  //////////Refreshing token if needed ///////////
  const authUser = useAuthUser();
  const [refreshCredentials] = useAPICall<{ id: string }, {}>(
    "refreshCredentials"
  );
  const [didRefresh, setDidRefresh] = useSessionState(
    false,
    "google-cred-did-refresh"
  );
  const [refreshing, setRefreshing] = useState(false);
  useEffect(() => {
    async function refresh() {
      if (!refreshing && authUser.id && !didRefresh) {
        setRefreshing(true);
        console.log(`Refresh credentials ${didRefresh}`);
        try {
          await refreshCredentials({ id: authUser.id });
        } catch {
          // ...
        }

        console.log(`Did update google credentials..`);
        setRefreshing(false);
        setDidRefresh(true);
      }
    }

    refresh();

    return () => {};
  }, [authUser]);

  //////////////////////////////////////////////
  const credentials = useCredentials();
  // const isGoogleConnected = credentials?.google !== null && credentials?.google !== undefined;
  const accessToken = credentials?.google?.access_token;

  const isGoogleConnected =
    accessToken !== null && accessToken !== undefined && didRefresh;

  const [open, setOpen] = useState(false);

  const [driveConfig, setDriveConfig] = useState<GoogleDriveConfig>({
    onSelect: () => {},
    type: "doc",
  });

  const contextProps: GoogleDriveContextProps = {
    isLoading: refreshing,
    isAvailable: isGoogleConnected,
    open: open,
    setOpen: setOpen,
    config: driveConfig,
    setConfig: setDriveConfig,
  };

  return (
    <GoogleDriveContext.Provider value={contextProps}>
      {children}
      {isGoogleConnected ? (
        <GoogleDrivePicker
          open={open}
          setOpen={setOpen}
          token={accessToken!}
          type={driveConfig.type}
          onSelect={driveConfig.onSelect}
        />
      ) : null}
    </GoogleDriveContext.Provider>
  );
}

export function useGoogleDrive() {
  const drive = useContext(GoogleDriveContext);
  return drive;
}
///////////////////////////////////

function GoogleDrivePicker({
  open,
  setOpen,
  token,
  type,
  onSelect,
}: PickerProps) {
  // The Browser API key obtained from the Google API Console.
  // Replace with your own Browser API key, or your own key.
  // var developerKey = "AIzaSyBSV8Z1Ku6Nr09hK30LKsZWYWvTznnN1rc";
  const developerKey = `AIzaSyAs5sqC2WWE0PGNV7SRLMxasgHk-7Vxhds`;
  // The Client ID obtained from the Google API Console. Replace with your own Client ID.
  var clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;

  // Replace with your own project number from console.developers.google.com.
  // See "Project number" under "IAM & Admin" > "Settings"
  var appId = "680477004738";

  // Scope to use to access user's Drive items.
  var scope = ["https://www.googleapis.com/auth/drive.file"];

  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);
  // const [authLoaded, setAuthLoaded] = useState(false);
  // const [oauthToken, setOauthToken] = useState(token);
  const oauthToken = token;
  const [picker, setPicker] = useState<google.picker.Picker | null>(null);

  // Use the Google API Loader script to load the google.picker script.
  function loadPicker() {
    gapi.load("client", { callback: onAuthApiLoad });
    // gapi.load("client:auth2", { callback: onAuthApiLoad });
    // gapi.load("script", { callback: onAuthApiLoad });
    gapi.load("picker", { callback: onPickerApiLoad });
  }

  async function onAuthApiLoad() {
    gapi.client.setApiKey(developerKey);
    gapi.client.setToken({
      access_token: oauthToken,
    });
  }

  function onPickerApiLoad() {
    setPickerApiLoaded(true);
  }

  // Create and render a Picker object for searching images.
  function createPicker(): google.picker.Picker {
    console.log(`Creating picker.`);

    var foldersView = new google.picker.DocsView()
      .setIncludeFolders(true)
      .setMimeTypes("application/vnd.google-apps.folder")
      // .setOwnedByMe(true)
      // .setEnableDrives(false)
      .setSelectFolderEnabled(true);

    var allDocsView = new google.picker.DocsView()
      .setIncludeFolders(true)
      // .setOwnedByMe(true)
      // .setEnableDrives(false)
      .setSelectFolderEnabled(false);

    var picker = new google.picker.PickerBuilder();

    if (type === "folder") {
      picker = picker.addView(foldersView);
    } else {
      picker = picker.addView(allDocsView);
    }

    picker = picker
      // .disableFeature(google.picker.Feature.SUPPORT_DRIVES)
      // .enableFeature(google.picker.Feature.MINE_ONLY)
      .setAppId(appId)
      .setOAuthToken(oauthToken)
      .setDeveloperKey(developerKey)
      .setCallback(pickerCallback);
    // .setOrigin("https://localhost:3000");

    const builtPicker = picker.build();
    return builtPicker;
  }

  // A simple callback implementation.
  function pickerCallback(data: google.picker.ResponseObject) {
    console.log(`Callback`);
    console.log(data);

    if (data.action == google.picker.Action.PICKED) {
      const doc = data.docs[0];
      var fileId = doc.id;
      onSelect({
        id: fileId,
        name: doc.name,
      });
    }
    if (
      data.action == google.picker.Action.CANCEL ||
      data.action == google.picker.Action.PICKED
    ) {
      setOpen(false);
    }
  }

  useEffect(() => {
    function showOrHide() {
      if (open) {
        const newPicker = createPicker();

        // await new Promise((resolve) => setTimeout(resolve, 3000));
        setPicker(newPicker);

        newPicker.setVisible(true);

        // Workaround to make picker visible above side menu
        var elements = document.getElementsByClassName("picker-dialog");
        for (var i = 0; i < elements.length; i++) {
          (elements[i] as any).style.zIndex = "2000";
        }
      } else {
        picker?.setVisible(open);
        setPicker(null);
      }
    }
    showOrHide();
  }, [open]);

  return (
    <ClientSideOnly>
      <Script
        type="text/javascript"
        src={`https://apis.google.com/js/api.js?key=${developerKey}`}
        onLoad={loadPicker}
      />
    </ClientSideOnly>
  );
}
