import React from "react";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { StyledMenu, StyledMenuItem } from "../StyledMenu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Avatar from "@mui/material/Avatar";
import { useUserOrNull } from "../../utils/contexts";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useAuthUser } from "next-firebase-auth";
import HelpButton from "./HelpButton";
import PersonIcon from "@mui/icons-material/Person";
import { MenuItem, Link } from "@mui/material";

import { useIntl } from "react-intl";

function _useStrings() {
  const intl = useIntl();

  const STRINGS = {
    SIGN_OUT_BTN: intl.formatMessage({
      id: "sign-out-btn",
      defaultMessage: "Wyloguj",
    }),
    PROFILE_BTN: intl.formatMessage({
      id: "profile-btn",
      defaultMessage: "Profil",
    }),
  };
  return STRINGS;
}

interface Props {}

export default function UserNavigationMenu({}: Props) {
  const _STRINGS = _useStrings();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const user = useUserOrNull();
  const AuthUser = useAuthUser();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    await AuthUser.signOut();
  };

  function logoutItem() {
    return (
      <StyledMenuItem
        onClick={() => {
          handleClose();
          // LOG OUT
        }}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary={_STRINGS.SIGN_OUT_BTN} onClick={signOut} />
      </StyledMenuItem>
    );
  }

  const profileItem = () => {
    return (
      <MenuItem component={Link} href="/profile">
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={_STRINGS.PROFILE_BTN} />
      </MenuItem>
    );
  };

  return (
    <div>
      <HelpButton />
      <Button color="info" onClick={handleClick}>
        {user?.displayName ? (
          <Avatar>{user!.displayName[0]}</Avatar>
        ) : (
          <Avatar />
        )}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {profileItem()}
        {logoutItem()}
      </StyledMenu>
    </div>
  );
}
