import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import CommentIcon from "@mui/icons-material/Comment";

import { useAuthUser } from "next-firebase-auth";
import { AuthClaim, UserRole } from "../../Shared/Types/User";
import GroupIcon from "@mui/icons-material/Group";
import FlagIcon from "@mui/icons-material/Flag";
import { useIntl } from "react-intl";

function _useStrings() {
  const intl = useIntl();
  const STRINGS = {
    NEWS: intl.formatMessage({
      id: "news-item",
      defaultMessage: "Komunikaty",
    }),
    USERS: intl.formatMessage({
      id: "users-item",
      defaultMessage: "Użytkownicy",
    }),

    PROJECTS: intl.formatMessage({
      id: "projects-item",
      defaultMessage: "Programy",
    }),
    ASSIGNMENTS: intl.formatMessage({
      id: "assignments-item",
      defaultMessage: "Realizacje",
    }),
    CALENDAR: intl.formatMessage({
      id: "calendar-item",
      defaultMessage: "Kalendarz",
    }),
    SYSTEM: intl.formatMessage({
      id: "system-item",
      defaultMessage: "System",
    }),
  };
  return STRINGS;
}

export function useMenuItemsDataSource() {
  const authUser = useAuthUser();
  const STRINGS = _useStrings();
  const userRole: UserRole =
    (authUser.claims["role"] as UserRole) ?? UserRole.unverified;

  var allItems: any[] = [];

  allItems.push({
    href: "/news",
    displayName: STRINGS.NEWS,
    icon: <CommentIcon />,
  });

  if (authUser.claims[AuthClaim.usersEditAll]) {
    allItems.push({
      href: "/users",
      displayName: STRINGS.USERS,
      icon: <GroupIcon />,
    });
  }

  if (authUser.claims[AuthClaim.projectsEditAll]) {
    allItems.push({
      href: "/projects",
      displayName: STRINGS.PROJECTS,
      icon: <FlagIcon />,
    });
  }

  // TODO: Who should have access?
  const assignmentsItem = {
    href: "/assignments",
    displayName: STRINGS.ASSIGNMENTS,
    icon: <AssignmentTurnedInIcon />,
  };
  if (userRole !== UserRole.unverified) {
    allItems.push(assignmentsItem);
  }

  const calendarItem = {
    href: "/calendar",
    displayName: STRINGS.CALENDAR,
    icon: <DateRangeIcon />,
  };
  if (
    userRole === UserRole.admin ||
    userRole === UserRole.normal ||
    userRole === UserRole.coach
  ) {
    allItems.push(calendarItem);
  }

  const systemConfigItem = {
    href: "/systemConfig",
    displayName: STRINGS.SYSTEM,
    icon: <RoomPreferencesIcon />,
  };

  if (authUser.claims[AuthClaim.systemEdit]) {
    allItems.push(systemConfigItem);
  }

  return allItems;
}
