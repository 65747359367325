import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";

interface Props extends CircularProgressProps {}
function LoadingIndicator(props: Props) {
  return <CircularProgress {...props} />;
}

export default LoadingIndicator;
