import { useState, useEffect } from "react";

export type StorageSingleKey = string | undefined;
type KeyPath = StorageSingleKey[];
export type StorageKeyOrPath = StorageSingleKey | KeyPath;

export default function useSessionState<T>(
  defaultValue: T,
  // When key is undefined, we're just using a local state - no persistence.
  keyOrPath: StorageKeyOrPath
): [T, (o: T) => void] {
  const [_needsRestoring, _setNeedsRestoring] = useState(true);
  let key: StorageSingleKey = undefined;

  // CALCULATE KEY
  if ((keyOrPath as any)?.reduce !== undefined) {
    const path = keyOrPath as KeyPath;
    if (path.length > 0) {
      key = path.reduce((prev, curr) => {
        if (prev !== undefined && curr !== undefined) {
          return `${prev}/${curr}`;
        } else {
          return undefined;
        }
      }, "");
    }
  } else {
    key = keyOrPath as StorageSingleKey;
  }

  // RESTORE IF NEEDED
  let RESTORED_DEFAULT_VALUE = defaultValue;
  if (_needsRestoring && window !== undefined) {
    if (key) {
      const savedData = sessionStorage?.getItem(key);
      if (savedData) {
        //console.log(`RESTORE: ${key} = ${savedData}`);
        const obj = JSON.parse(savedData);
        RESTORED_DEFAULT_VALUE = obj as T;
      }
    }
    _setNeedsRestoring(false);
  }

  // Value state
  const [value, setValue] = useState<T>(RESTORED_DEFAULT_VALUE);

  const _setAndSave = (newValue: T) => {
    setValue(newValue);
    if (key) {
      //console.log(`SAVE: ${key} = ${newValue}`);
      const json = JSON.stringify(newValue);
      sessionStorage?.setItem(key, json);
    }
  };

  return [value, _setAndSave];
}
