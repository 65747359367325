import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Link from "next/link";
import Image from "next/image";
import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMenuItemsDataSource } from "./MenuItemsDataSource";
import { useRouter } from "next/router";

interface Props {
  open: boolean;
  setOpen: (b: boolean) => void;
}
export const DRAWER_WIDTH = 240;

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MenuSidebar({ open, setOpen }: Props) {
  const theme = useTheme();
  const router = useRouter();
  const menuItems = useMenuItemsDataSource();

  const drawer = () => {
    return (
      <>
        <DrawerHeader>
          <div
            style={{
              flexGrow: 1,
              height: 60,
              position: "absolute",
              left: 0,
              right: 0,
            }}
          >
            <Link href="/">
              <a>
                <Image
                  src="/LOGO_IPZIN.png"
                  layout="fill"
                  objectFit="contain"
                />
              </a>
            </Link>
          </div>
          <IconButton onClick={() => setOpen(!open)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <Link key={item.href} href={item.href}>
              <a>
                <ListItem button selected={router.pathname.includes(item.href)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.displayName} />
                </ListItem>
              </a>
            </Link>
          ))}
        </List>
      </>
    );
  };
  // const container = window !== undefined ? () => window.document.body : undefined;

  const drawerProps: DrawerProps = {
    sx: {
      display: "block",
      width: DRAWER_WIDTH,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        width: DRAWER_WIDTH,
        boxSizing: "border-box",
      },
    },
    anchor: "left",
    open: open,
    onClose: () => setOpen(false),
    ModalProps: {
      keepMounted: true, // Better open performance on mobile
    },
  };

  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  drawerProps.variant = matches ? "persistent" : "temporary";

  return (
    <Box component="nav">
      <Drawer {...drawerProps}>{drawer()}</Drawer>
    </Box>
  );
}
