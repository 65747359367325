import React, { PropsWithChildren, Suspense, useEffect } from "react";
import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import MenuSidebar, { DrawerHeader, DRAWER_WIDTH } from "../Menu/MenuSidebar";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import UserNavigationMenu from "../Users/UserNavigationMenu";
import { useRouter } from "next/router";
import { GoogleDriveContextProvider } from "../GeneratedFileElementContent/GoogleDrivePicker";
import { useAuthUser } from "next-firebase-auth";
import { UserRole } from "../../Shared/Types/User";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import LoadingIndicator from "../LoadingIndicator";
import { useAppState, useUserOrNull } from "~/utils/contexts";
import { usersCollection } from "~/utils/firebase/collections";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  left: 0,
  right: 0,
  padding: 0,
  margin: 0,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.up("sm")]: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: `${DRAWER_WIDTH}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${DRAWER_WIDTH}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  },
}));

export default function AdminApp({ children }: PropsWithChildren<{}>) {
  const [open, setOpen] = useState(true); //false
  const title = useCurrentPageTitle();
  const authUser = useAuthUser();
  const user = useUserOrNull();
  const router = useRouter();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const [appState, setAppState] = useAppState();

  useEffect(() => {
    const setDefaultMenu = () => {
      if (isSmallScreen) {
        // Menu closed by default
        setOpen(false);
      }
    };
    setDefaultMenu();
  }, []);

  useEffect(() => {
    async function verifyClaims() {
      if (!authUser.clientInitialized) {
        return;
      }

      const role: UserRole =
        (authUser.claims["role"] as UserRole) ?? UserRole.unverified;

      if (user?.requirePasswordReset ?? false) {
        if (
          !router.pathname.includes("resetPass") &&
          !appState._didRedirectToResetPass
        ) {
          // const socialProviders =
          //   authUser.firebaseUser?.providerData.filter(
          //     (d) => d?.providerId !== "password"
          //   ) ?? [];
          // const hasSocialProvider = socialProviders.length > 0;
          // if (hasSocialProvider) {
          //   // If we have social provider, just finish the flow.
          //   await usersCollection.doc(user!.id).update({
          //     requirePasswordReset: false,
          //   });
          // }

          console.log(
            `Redirect user to change pass. ${user?.requirePasswordReset}`
          );
          setAppState({ ...appState, _didRedirectToResetPass: true });
          router.push("/resetPass");
        }
      } else if (role === UserRole.unverified) {
        if (!router.pathname.includes("unverified")) {
          router.push("/unverified");
        }
      }
    }
    verifyClaims();
  }, [
    authUser.clientInitialized,
    authUser.claims["role"],
    user?.requirePasswordReset,
  ]);

  if (!authUser.clientInitialized || user === null) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <CssBaseline />
      <GoogleDriveContextProvider>
        <div style={{ display: "flex", zIndex: 10 }}>
          <AppBar position="fixed" open={open}>
            <Toolbar>
              {/* TODO: Why the right buttons are jumping when drawer is open in mobile-mode */}
              {open ? null : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setOpen(!open)}
                  edge="start"
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              )}

              <Typography variant="h6" noWrap>
                {title}
              </Typography>
              <div className={"spacer"} />
              <UserNavigationMenu />
            </Toolbar>
          </AppBar>

          <MenuSidebar open={open} setOpen={setOpen} />

          <Main open={open}>
            <DrawerHeader />
            <div>{children}</div>
          </Main>
        </div>
      </GoogleDriveContextProvider>
    </div>
  );
}

function useCurrentPageTitle(): string {
  const [title, setTitle] = useState("");
  const router = useRouter();
  useEffect(() => {
    setTitle(document.title);
    router.events.on("routeChangeComplete", () => {
      setTitle(document.title);
    });
  }, []);

  return title;
}
